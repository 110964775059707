<template>
	<div style="background-color: #f7f8fa">
		<div class="signUp margin_auto">
			<div class="signUp_1">
				<span>填写报名信息</span>
			</div>
			<div
				class="signUp_2 flex direction_column justify_content_c align_items_c"
			>
				<div class="signUp_2_form">
					<div class="signUp_2_form_item flex justify_content_sb align_items_c">
						<div>*姓名</div>
						<input type="text" />
					</div>
					<div class="signUp_2_form_item flex justify_content_sb align_items_c">
						<div>*手机</div>
						<input type="text" />
					</div>
					<div class="signUp_2_form_item flex justify_content_sb align_items_c">
						<div>*邮箱</div>
						<input type="text" />
					</div>
					<div class="signUp_2_form_item flex justify_content_sb align_items_c">
						<div>*微信</div>
						<input type="text" />
					</div>
					<div class="signUp_2_form_item flex justify_content_sb align_items_c">
						<div>*公司</div>
						<input type="text" />
					</div>
					<div class="signUp_2_form_item flex justify_content_sb align_items_c">
						<div>*职位</div>
						<input type="text" />
					</div>
					<div class="signUp_2_form_item_ch">
						<span>参会目的</span>
						<textarea name="" id="" cols="30" rows="10"></textarea>
					</div>
					<div class="subBtn flex justify_content_c align_items_c"> 提交 </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
};
</script>

<style scoped>
.signUp {
	padding-top: 34px;
	width: 751px;
	padding-bottom: 120px;
}
.signUp_1 {
	width: 100%;
	height: 191px;
	background-image: url("https://zy.metaera.media/assets/images/signUp/36.png");
	background-size: 100% 100%;
}
.signUp_1 span {
	font-size: 55px;
	font-family: DingTalk JinBuTi-Regular, DingTalk JinBuTi;
	font-weight: 400;
	color: #ffffff;
	position: relative;
	top: 43px;
	left: 45px;
}
.signUp_2 {
	width: 100%;

	background: #ffffff;
	border-radius: 26px 26px 26px 26px;
	opacity: 1;
	border: 1px solid #ffffff;
	position: relative;
	top: -42px;
	padding-bottom: 40px;
}
.signUp_2_form {
	width: 409px;
}
.signUp_2_form_item {
	width: 100%;
	height: 48px;
	background: #ffffff;
	border-radius: 5px 5px 5px 5px;
	opacity: 1;
	border: 1px solid #939395;
	margin-top: 20px;
}
.signUp_2_form_item div {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-left: 10px;
}
.signUp_2_form_item input {
	width: 60%;
	height: 90%;
	border: none;
	outline: none;
	text-align: right;
	padding-right: 12px;
}
.signUp_2_form_item_ch {
	margin-top: 20px;
}
.signUp_2_form_item_ch span {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #676767;
}
.signUp_2_form_item_ch textarea {
	width: 100%;
	height: 88px;
	background: #ffffff;
	border-radius: 5px 5px 5px 5px;
	opacity: 1;
	border: 1px solid #939395;
	margin-top: 10px;
}
.subBtn {
	width: 242px;
	height: 42px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 6px 6px 6px 6px;
	opacity: 1;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	margin: 0 auto;
	margin-top: 40px;
}
</style>
